import { motion } from 'framer-motion'

const AboutPage = ()=> {
    return(
        <>
            <motion.div 
                className="about--container"
                initial={{opacity: 0, transition: {duration: 0.3 }, overflow: "hidden"}}
                animate={{opacity: 1, transition: {duration: 0.3 }}}
                exit={{opacity: 0, transition: {duration: 0.2 }}}
            >
                <h1>Front-End Web Developer with an <strong>Eye</strong> for <strong>UI</strong></h1>
                <p>
                    My name is Alex, a UI developer who specializes in Front-end Development and UI Design. I have a love for learning, and an eye for small details – both traits that have helped me over my 5+ year career in web development.
                </p>
                <p>
                    I am fueled by my passion to bring concepts on paper into reality online, and by the nuances of successfully creating an online identity - one that accurately represents the concept and is aesthetically pleasing and easy to navigate.     
                </p>
            </motion.div>
        </>
    )
};

export default AboutPage;
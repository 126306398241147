import { Link } from "react-router-dom";
import { Component } from 'react';

class SiteNavigation extends Component {

    constructor(props){
        super(props);
        this.state = { className: 'menu-collapsed' }
        this.updateState = this.updateState.bind(this);
    }

    updateState(){
        this.state.className === 'menu-collapsed'
        ?
        this.setState({className: 'menu-expanded'})
        :
        this.setState({className: 'menu-collapsed'})
    }

    render(){
        return(
            <>
                <div className="nav">
                    <div className="logo">
                        <h3><Link to="/">Alex <span>Sanchez</span></Link></h3>
                    </div>
                    <div className={this.state.className}>
                        <div className="bar" onClick={this.updateState}></div>
                        <nav>
                            <ul className="nav--menu">
                                <li><Link to="/" onClick={this.updateState}>Home</Link></li>
                                <li><Link to="About" onClick={this.updateState}>About</Link></li>
                                <li><Link to="Work" onClick={this.updateState}>Work</Link></li>
                                <li><Link to="Contact" onClick={this.updateState}>Contact</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </>
        )
    }
};

export default SiteNavigation;
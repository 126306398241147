import './App.css';
import SiteNavigation from './components/SiteNavigation';
import AnimatedRoutes from './components/AnimatedRoutes';

function App() {
  return (
    <>
      <SiteNavigation />
      <div className="page--container">
        <div className="email" id="email">
          E: <a href="mailto: alex.sanchez.webdev@gmail.com">alex.sanchez.webdev@gmail.com</a>
        </div>
        <AnimatedRoutes />
        <div className="socials">
          <div className="follow-me">
            <p>Follow Me</p>
          </div>
          <a href="https://www.linkedin.com/in/alexander-sanchez-b65730223" target="_blank"><img src="https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/icons8-linkedin-circled-50_sJeHR7OJS.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677515972271" alt="social media" /></a>
          <a href="https://github.com/sancheza2389" target="_blank"><img src="https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/icons8-github-50_2VTm2Am3r.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677515971623" alt="social media" /></a>
        </div>
      </div>
    </>
  );
}

export default App;

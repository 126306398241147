import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Hero from './Hero';
import AboutPage from './AboutPage';
import WorkPage from './WorkPage';
import ContactPage from './ContactPage';

const AnimatedRoutes = ()=> {

    return(
        <>
            <AnimatePresence>
                <Routes>
                    <Route path="/" element={ <Hero/> }/>
                    <Route path="About" element={ <AboutPage/> }/>
                    <Route path="Work" element={ <WorkPage/> }/>
                    <Route path="Contact" element={ <ContactPage/> }/>
                </Routes>
            </AnimatePresence> 
            
        </>
    )
};

export default AnimatedRoutes;
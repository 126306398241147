import { motion } from 'framer-motion'

const Hero = ()=> {
    return(
        <>
            <motion.div 
                className="hero--container"
                initial={{opacity: 0, transition: {duration: 0.3 }, overflow: "hidden"}}
                animate={{opacity: 1, transition: {duration: 0.3 }}}
                exit={{opacity: 0, transition: {duration: 0.2 }}}
            >
                <h2>Hello, I'm Alex Sanchez</h2>
                <h1>I design & code <strong>websites</strong></h1>
            </motion.div>
        </>
    )
};

export default Hero;
import WorkItem from "./WorkItem";
import { motion } from 'framer-motion'

const WorkPage = ()=> {
    return(
        <>
            <motion.div 
                className="work--container"
                initial={{opacity: 0, transition: {duration: 0.3 }, overflow: "hidden"}}
                animate={{opacity: 1, transition: {duration: 0.3 }}}
                exit={{opacity: 0, transition: {duration: 0.2 }}}
            >
                <h1>Featured <strong>Projects</strong></h1>
                <WorkItem />
            </motion.div>
        </>
    )
};

export default WorkPage;
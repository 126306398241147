const listitems = [
    {
        key: 1,
        image: "https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/RB__2__v6szvxWnF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677524428495",
        title: "Digital Agency",
        subtitle: "WebZemi, LLC",
        list1: ["HTML", "SCSS", "ES6", "PHP"],
        list2: ["WordPress", "PHP Mailer", "Figma"],
        address: "https://webzemi.com/",
        alt: "Webzemi Digital Agency"
    },
    {
        key: 2,
        image: "https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/RB_7avWs0FrV.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677524428159",
        title: "Portfolio",
        subtitle: "Ruby Bonilla, Content Writer",
        list1: ["React.JS", "Router", "Axios", "Framer-Motion"],
        list2: ["SCSS", "JSX", "Figma", "Canva"],
        address: "https://www.rubybonilla.com",
        alt: "Ruby Bonilla Content Writer"
    },
    {
        key: 3,
        image: "https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/XEBEC%20(3)_lSK_ceaar.png?updatedAt=1715181724492",
        title: "True Citrus",
        subtitle: "True Citrus Homepage Redesign",
        list1: ["Shopify", "Liquid", "Pixlee", "Destini"],
        list2: ["Unbounce", "JavaScript", "Figma", "Canva"],
        address: "https://www.truelemon.com/",
        alt: "true lemon homepage redesign"
    },
    {
        key: 4,
        image: "https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/XEBEC_fnZ0okvKQ.png?updatedAt=1715181724431",
        title: "Xebec",
        subtitle: "Xebec Snap Page",
        list1: ["Shopify", "Liquid", "HTML", "CSS"],
        list2: ["JavaScript", "Figma", "Canva"],
        address: "https://www.thexebec.com/pages/snap-story",
        alt: "xebec snap page"
    },
    {
        key: 5,
        image: "https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/XEBEC%20(2)_ajEk_vhV8.png?updatedAt=1715181724624",
        title: "Stronger U Nutrition",
        subtitle: "Stronger U/Fitness Anytime Homepage Redesign",
        list1: ["WordPress", "PHP", "JavaScript", "jQuery"],
        list2: ["HTML", "CSS", "Figma", "Canva"],
        address: "https://strongeru.com/strongeranytime/",
        alt: "stronger u/fitness anytime redesigned homepage"
    },
    {
        key: 6,
        image: "https://ik.imagekit.io/webzemi/Alex-Portfolio/v2/XEBEC%20(1)_Rr7wUz2tj.png?updatedAt=1715181724467",
        title: "Stronger U Nutrition",
        subtitle: "Stronger U Pricing Page",
        list1: ["WordPress", "PHP", "JavaScript", "jQuery"],
        list2: ["HTML", "CSS", "Figma", "Canva"],
        address: "https://strongeru.com/pricing-2/",
        alt: "Stronger u nutrition pricing page"
    }
]

const WorkItem = ()=> {
    return(
        <>
            <div className="work-item--container">
                <div className="work-item">
                    <a href="https://www.truelemon.com/" target="_blank"><img alt={listitems[2].alt} src={listitems[2].image} /></a>
                    <h3>{listitems[2].title}</h3>
                    <h4>{listitems[2].subtitle}</h4>
                    <p>Tech Used:</p>
                    <div className="work-item--list">
                        <ul>{listitems[2].list1.map(text => <li>{text}</li>)}</ul>
                        <ul>{listitems[2].list2.map(text => <li>{text}</li>)}</ul>
                    </div>
                    <div className="work-item--link">
                        <a href={listitems[2].address} target="_blank">View Live</a>
                    </div>
                </div>
                <div className="work-item">
                    <a href="https://www.thexebec.com/pages/snap-story" target="_blank"><img alt={listitems[3].alt} src={listitems[3].image} /></a>
                    <h3>{listitems[3].title}</h3>
                    <h4>{listitems[3].subtitle}</h4>
                    <p>Tech Used:</p>
                    <div className="work-item--list">
                        <ul>{listitems[3].list1.map(text => <li>{text}</li>)}</ul>
                        <ul>{listitems[3].list2.map(text => <li>{text}</li>)}</ul>
                    </div>
                    <div className="work-item--link">
                        <a href={listitems[3].address} target="_blank">View Live</a>
                    </div>
                </div>
                <div className="work-item">
                    <a href="https://strongeru.com/strongeranytime/" target="_blank"><img alt={listitems[4].alt} src={listitems[4].image} /></a>
                    <h3>{listitems[4].title}</h3>
                    <h4>{listitems[4].subtitle}</h4>
                    <p>Tech Used:</p>
                    <div className="work-item--list">
                        <ul>{listitems[4].list1.map(text => <li>{text}</li>)}</ul>
                        <ul>{listitems[4].list2.map(text => <li>{text}</li>)}</ul>
                    </div>
                    <div className="work-item--link">
                        <a href={listitems[4].address} target="_blank">View Live</a>
                    </div>
                </div>
                <div className="work-item">
                    <a href="https://strongeru.com/pricing-2/" target="_blank"><img alt={listitems[5].alt} src={listitems[5].image} /></a>
                    <h3>{listitems[5].title}</h3>
                    <h4>{listitems[5].subtitle}</h4>
                    <p>Tech Used:</p>
                    <div className="work-item--list">
                        <ul>{listitems[5].list1.map(text => <li>{text}</li>)}</ul>
                        <ul>{listitems[5].list2.map(text => <li>{text}</li>)}</ul>
                    </div>
                    <div className="work-item--link">
                        <a href={listitems[5].address} target="_blank">View Live</a>
                    </div>
                </div>
                <div className="work-item">
                    <a href="https://webzemi.com/" target="_blank"><img alt={listitems[0].alt} src={listitems[0].image} /></a>
                    <h3>{listitems[0].title}</h3>
                    <h4>{listitems[0].subtitle}</h4>
                    <p>Tech Used:</p>
                    <div className="work-item--list">
                        <ul>{listitems[0].list1.map(text => <li>{text}</li>)}</ul>
                        <ul>{listitems[0].list2.map(text => <li>{text}</li>)}</ul>
                    </div>
                    <div className="work-item--link">
                        <a href={listitems[0].address} target="_blank">View Live</a>
                    </div>
                </div>
                <div className="work-item">
                    <a href="https://www.rubybonilla.com" target="_blank"><img alt={listitems[1].alt} src={listitems[1].image} /></a>
                    <h3>{listitems[1].title}</h3>
                    <h4>{listitems[1].subtitle}</h4>
                    <p>Tech Used:</p>
                    <div className="work-item--list">
                        <ul>{listitems[1].list1.map(text => <li>{text}</li>)}</ul>
                        <ul>{listitems[1].list2.map(text => <li>{text}</li>)}</ul>
                    </div>
                    <div className="work-item--link">
                        <a href={listitems[1].address} target="_blank">View Live</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default WorkItem;
import axios from 'axios';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

type FormState = {
    name: String;
    email: String;
    subject: String;
    message: String;
};
type ServiceMessage = {
    class: string;
    text: string;
};

const ContactBox = ()=> {
    const formId = "cpEfEMbw";
    const formSparkUrl = `https://submit-form.com/${formId}`;
    const recaptchaKey = '6LcBQUokAAAAAN09Etsg0r2i7fcVJ0QqVnFBB8bj';
    const recaptchaRef = useRef<any>();

    const initialFormState = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }

    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [message, setMessage] = useState<ServiceMessage>();
    const [recaptchaToken, setRecaptchaToken] = useState<string>();

    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setSubmitting(true);
        await postSubmission();
        setSubmitting(false);
    };

    const postSubmission = async () => {
        const payload = {
            ...formState,
            "g-recaptcha-response": recaptchaToken
        };

        try {
            const result = await axios.post(formSparkUrl, payload);
            //console.log(result);
            setMessage({
                class: "green message",
                text: "Thank you, I will reach out soon!",
            });
            setTimeout(()=>{
                setMessage({
                    class: "message message-hidden",
                    text: "Thank you, I will reach out soon!",
                });
            }, 2000);
            setFormState(initialFormState);
            recaptchaRef.current.reset();
        } catch(error) {
            //console.log(error);
            setMessage({
                class: "red message",
                text: "Sorry, there was a error. Please try again",
            });
            setTimeout(()=>{
                setMessage({
                    class: "message message-hidden",
                    text: "Sorry, there was a error. Please try again",
                });
            }, 2000);
        }
    };
    const updateFormControl = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = event.target;
        const formKey = id;
        const updatedFormState = { ...formState };
        updatedFormState[formKey] = value;
        setFormState(updatedFormState);
    };
    const updateRecaptchaToken = (token: string | null) => {
        setRecaptchaToken(token as string);
    };

    

    return(
        <>
            <form onSubmit={submitForm} className="contact--form">
                <div className="contact-form--input">
                    <label htmlFor="name">Name</label>
                    <input 
                        onChange={updateFormControl}
                        type="text" 
                        id="name" 
                        value={formState.name}
                        placeholder="Name"
                        required
                    />
                </div>
                <div className="contact-form--input">
                    <label htmlFor="email">email</label>
                    <input 
                        onChange={updateFormControl}
                        type="email" 
                        id="email" 
                        value={formState.email}
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="contact-form--input">
                    <label htmlFor="message">Message..</label>
                    <textarea 
                        onChange={updateFormControl}
                        type="text" 
                        id="message" 
                        value={formState.message}
                        placeholder="Message"
                        required
                    ></textarea>
                </div>
                <div className="contact-form--submit">
                    <div className="captcha" style={{transform:"scale(0.75)", transformOrigin:"0 0"}}>
                        <ReCAPTCHA 
                            ref={recaptchaRef}
                            theme="dark"
                            sitekey={recaptchaKey}
                            onChange={updateRecaptchaToken}
                        />
                    </div>
                    <div className="button">
                        <button 
                            name="button" 
                            type="submit"
                            disabled={submitting}
                            className="button-submit"
                            > 
                            {submitting ? "SUBMITTING..." : "SUBMIT"} 
                        </button>
                    </div>
                </div>
            </form>
        </>
    )

};

export default ContactBox;
import ContactBox from "./ContactBox.tsx";
import { motion } from 'framer-motion'

const ContactPage = ()=> {
    return(
        <>
            <motion.div 
                className="contact--container"
                initial={{opacity: 0, transition: {duration: 0.3 }, overflow: "hidden"}}
                animate={{opacity: 1, transition: {duration: 0.3 }}}
                exit={{opacity: 0, transition: {duration: 0.2 }}}
            >
                <h1>Want to <strong>Connect</strong>?</h1>
                <div className="contact--info">
                    <p>If you have an idea for a website that you’d like to bring to life, I’d love to hear about it! Please feel free to reach out to me and I will get back to you within 2 business days.</p>
                    <ul>
                        <li>Currently on the <strong>East Coast</strong> (Will Work <strong>Remotely</strong>)</li>
                        <li>Currently taking projects</li>
                        <li>Available for employment. <a href="..\public\Alex Sanchez Front-End Developer Resume.pdf" download="Alex Sanchez Front-End Developer Resume.pdf">Download Resume</a></li>
                    </ul>
                </div>
                <ContactBox />
            </motion.div>
        </>
    )
};

export default ContactPage;